import { Box, ImageList, ImageListItem } from '@mui/material'
import { graphql } from 'gatsby'
import React from 'react'
import orianeLogosConfig from '../../../configs/orianeLogosConfig.js'
import getEntityLabel from '../../../EntityUtils/getEntityLabel.js'

export const query = graphql`
fragment OntologyContributorsCard on Query{
  owlOntology(id: {eq: $id}) {
    id
    sch_contributor {
      id
      name {
        _language
        _value
      }
      logo {
        id
        identifier
      }
    }
    contributor {
      _language
      _value
      id
    }
  }
}
`

console.warn('@TODO: add display management of `contributor` field')
// display of schema_contributor & dct_contributor
export default function OntologyContributorsCard(props) {
  const { owlOntology } = props.data
  if (!owlOntology) return (<div />)
  const { sch_contributor } = owlOntology
  return (
    <Box sx={{ overflowY: 'scroll' }}>
      {sch_contributor ?
        <ImageList variant="masonry" cols={5} gap={8}>
          {
            sch_contributor.map((contrib) => (
              <ImageListItem key={contrib.id}>
                <img
                  src={getImageJsFromId(contrib.id)}
                  alt={getEntityLabel(contrib)}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </ImageList>
        : null // nothing else
      }
      {/* @TODO: add the contributor case here: */}

    </Box>
  )
}

function getImageJsFromId(id) {
  let name = id.split(':')[1]
  name = name.includes('/') ? name.split('/')[1] : name
  const jsRef = orianeLogosConfig[name]
  if (!jsRef) {
    console.warn('No js image ref found for', id)
    return orianeLogosConfig.mindMatcher
  }
  return jsRef
}

import React from 'react'
import { graphql } from "gatsby"
import LiteralsAsText from '../baseComponents/LiteralsAsText'

// @TODO: create a generic LiteralsComponent based on
//      - a graphql interface hierarchy : https://www.gatsbyjs.com/docs/reference/graphql-data-layer/schema-customization/#custom-interfaces-and-unions
//      - a merge of : gatsby/src/components/dashorm/cardComponents/ConceptSchemeLiteralsComponent.js
//      - and : gatsby/src/components/dashorm/cardComponents/ClassLiteralsComponent.js
// --> issue to create


export const query = graphql`
fragment OntologyLiteralsCard on Query{
  owlOntology(id: {eq: $id}) {
    id
    declare
    comment {
      _language
      _value
    }
    created {
      _value(locale: "fr")
    }
    creator {
      _language
      _value
    }
    date(locale: "fr")
    description {
      _language
      _value
      type
    }
    label {
      _language
      _value
    }
    license {
      id
    }
    # @TODO: restaure
    # modified {
    #  _value(locale: "fr")
    # }
    seeAlso {
      id
    }
    title {
      _language
      _value
    }
    versionInfo
    versionIRI {
      id
    }
    suggestedShapesGraph {
      id
    }
  }
}
`

export default function OntologyLiteralsCard(props) {
  // @TODO: find a better way to get all properties in query because :
  //   console.log(query) return a number... strange
  //   Object.keys(owlOntology) will return all the fields from all cards. So we will have to define an "exclusion list" here
  // ==> maybe the better solution is ontology (or @context) parsing
  const { owlOntology } = props.data
  const literals = [
    'title',
    'declare',
    'comment',
    'created',
    'creator',
    'date',
    'description',
    'label',
    'license',
    'modified',
    'seeAlso',
    'versionInfo',
    'versionIRI',
    'suggestedShapesGraph',
  ]

  return (
    <LiteralsAsText entity={owlOntology} literalsConfig={literals} />
  )
}

import React from 'react'
import { graphql } from 'gatsby'
import initDashTree from './dashutils/initDashTree.js'
import getCardNode from './dashutils/getCardNode.js'
import addCardNode from './dashutils/addCardNode.js'
import * as dashNodeComponents from './dashNodeComponents'
import EntityInfoComponent from './cardComponents/EntityInfoComponent'
import ListLinksComponent from './cardComponents/ListLinksComponent'
import OccupationInfoComponent from './cardComponents/OccupationInfoComponent'
import ClassInfoComponent from './cardComponents/ClassInfoComponent'
import ClassLiteralsComponent from './cardComponents/ClassLiteralsComponent'
import TodoComponent from './cardComponents/TodoComponent.js'
import ObjectMermaidComponent from './cardComponents/ObjectMermaidComponent.js'
import ObjectPropertiesComponent from './cardComponents/ObjectPropertiesComponent.js'
import ObjectMoreSpecificTypesComponent from './cardComponents/ObjectMoreSpecificTypesComponent.js'
import ObjectValueOfPropertiesComponent from './cardComponents/ObjectValueOfPropertiesComponent.js'
import Seo from '../seo.js'
import DashHeart from '../dashibiden/DashHeart.js'
import ConceptLiteralsComponent from './cardComponents/ConceptLiteralsComponent.js'
import OntologyContributorsCard from './cardComponents/OntologyContributorsCard.js'
import getEntityLabel from '../../EntityUtils/getEntityLabel.js'
import OntologyLiteralsCard from './cardComponents/OntologyLiteralsCard.js'


const components = {
  // generic dashTree properties
  ...dashNodeComponents,
  // generic components
  'EntityInfoComponent': EntityInfoComponent,
  'ListLinksComponent': ListLinksComponent,
  // for esco :
  'OccupationInfoComponent': OccupationInfoComponent,

  // utils
  'TodoComponent': TodoComponent,

  // for rdfs classes
  'ClassInfoComponent': ClassInfoComponent,
  'ClassLiteralsComponent': ClassLiteralsComponent,
  'ObjectMermaidComponent': ObjectMermaidComponent,
  'ObjectPropertiesComponent': ObjectPropertiesComponent,
  'ObjectMoreSpecificTypesComponent': ObjectMoreSpecificTypesComponent,
  'ObjectValueOfPropertiesComponent': ObjectValueOfPropertiesComponent,

  // for skos classes:
  // 'ConceptSchemeLiteralsComponent': ConceptSchemeLiteralsComponent,
  'ConceptLiteralsComponent': ConceptLiteralsComponent,

  // for owl:Ontology
  'OntologyContributorsCard': OntologyContributorsCard,
  'OntologyLiteralsCard': OntologyLiteralsCard,
}


export const query = graphql`
query owlOntologyQuery($id: String) {

  entity: owlOntology(id: {eq: $id}) {
    id
    title {
      _language
      _value
    }
    comment {
      _language
      _value
    }
  }

}
`
// @TODO : review it with the more recent `DashTreeLayout7` structure
export default function DashLayoutOwlOntology(props) {
  
  const dashTree = initDashTree()

  // explanation: data comme from the page static query and pageData from the fragments embedded in the component
  // create a single object from this 2 sources in "page data"
  const { entity } = props.data
  const { pageData } = props.pageContext
  pageData.entity = entity

  const entityName = getEntityLabel(entity)

  const literals = getCardNode(pageData, 'OntologyLiteralsCard', {
    cardName: entityName,
    columnSize: 12,
    showContribution: true,
  })
  addCardNode(dashTree, literals, 0, 1)

  const contributors = getCardNode(pageData, 'OntologyContributorsCard', { cardName: 'Les contributeurs :', columnSize: 12, })
  addCardNode(dashTree, contributors, 3, 1)

  return (
    <>
      <Seo title={entityName} />
      <DashHeart dashTree={dashTree} components={components} />
    </>
  );
}


// Auto-generated file from `integrate` script execution with `-e` & `-u`.
// See the integrate command in this example : gatsby/data/oriane-rdf-release/README.md
import cdg from '../../logos-data/cdg.svg'
import cy from '../../logos-data/cy.svg'
import jr from '../../logos-data/jr.svg'
import lyli from '../../logos-data/lyli.png'
import mindMatcher from '../../logos-data/mindMatcher.png'
import orientoi from '../../logos-data/orientoi.svg'
import prometheusX from '../../logos-data/prometheusX.svg'
import visions from '../../logos-data/visions.svg'
import academieDeVersailles from '../../logos-data/academieDeVersailles.jpg'
import inokufu from '../../logos-data/inokufu.svg'
import regionIleDeFrance from '../../logos-data/regionIleDeFrance.png'
import spro from '../../logos-data/spro.jpg'

const orianeLogosConfig = {
  cdg,
  cy,
  jr,
  lyli,
  mindMatcher,
  orientoi,
  prometheusX,
  visions,
  academieDeVersailles,
  inokufu,
  regionIleDeFrance,
  spro,
}

export default orianeLogosConfig
